/** important for Invitation messages in safari */
div,
p {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	/* cursor: default; */
}

[contenteditable] {
	-webkit-user-select: text;
	user-select: text;
	cursor: text;
}

/** Prevent color on input fields */
/* ::-internal-autofill-selected {
	background-color: #ffffff;
} */

/** Add gazpacho font */
@font-face {
	font-family: Gazpacho;
	font-style: normal;
	font-weight: 900;
	font-display: swap;
	src: url('/assets/fonts/gazpacho_900.woff2') format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
		U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/** PINTURA Overwrites */
@media only screen and (max-width: 640px) {
	.PinturaTabList [role='tab'] {
		flex-direction: inherit;
	}
}

/** Styles for service worker waiting to install prompt (swPromptForUpdate) */
.confirm {
	position: fixed;
	z-index: 100;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.6);
	padding: 10px;
	box-sizing: border-box;

	opacity: 0;
	animation-name: confirm---open;
	animation-duration: 0.2s;
	animation-fill-mode: forwards;

	display: flex;
	align-items: center;
	justify-content: center;
}

.confirm--close {
	animation-name: confirm---close;
}

.confirm__window {
	width: 100%;
	max-width: 448px;
	background: white;
	font-size: 16px;
	border-radius: 5px;
	overflow: hidden;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);

	opacity: 0;
	transform: scale(0.75);
	animation-name: confirm__window---open;
	animation-duration: 0.2s;
	animation-fill-mode: forwards;
	animation-delay: 0.2s;
}

.confirm__buttons {
	padding: 1.25em;
}

.confirm__titlebar {
	background: #0066cc;
	color: #ffffff;
	display: flex;
	align-items: center;
	justify-content: space-around;
	padding: 1em;
}

.confirm__title {
	font-weight: bold;
	font-size: 1.1em;
}

.confirm__title-container {
	display: flex;
	justify-content: center;
	align-items: center;
	flex: 1;
	padding: 0.4em 0.8em;
}

.confirm__close {
	background: none;
	outline: none;
	border: none;
	transform: scale(2);
	color: #ffffff;
	transition: color 0.15s;
}

.confirm__close:hover {
	color: #ff0000;
	cursor: pointer;
}

.confirm__content {
	line-height: 1.5em;
	text-align: center;
	padding: 1em;
	color: #3b4e60;
}

.confirm__buttons {
	background: #eeeeee;
	display: flex;
	justify-content: center;
}

.confirm__button {
	padding: 0.5em 1.5em;
	border: 2px solid #0066cc;
	border-radius: 21px;
	background: #ffffff;
	color: #0066cc;
	font-weight: bold;
	font-size: 1em;
	margin-left: 0.6em;
	cursor: pointer;
	outline: none;
}

.confirm__button--fill {
	background: #0066cc;
	color: #ffffff;
}

.confirm__button:focus {
	box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
}

@keyframes confirm---open {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes confirm---close {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}

@keyframes confirm__window---open {
	to {
		opacity: 1;
		transform: scale(1);
	}
}
